<template>
  <div class="login_box">
    <!-- <el-button @click="changeLanguage()">{{$t('login.switch')}}</el-button>
    <span>{{$t("login.username")}}</span>
    <el-button @click="toSiteList()">{{$t('login.intoSystem')}}</el-button> -->
    <div class="container">
      <!-- 公司logo -->
      <!-- <div class="avatar_box margin-bottom" style="float: left">
        <img src="../assets/imgs/logo.png" alt="" style="width: 60px" />
      </div> -->
      <div class="avatar_box margin-bottom" style="float: left">
        <img
          src="../assets/imgs/logo2.png"
          alt=""
          style="width: 250px; margin: -20px"
        />
      </div>
      <h2>ViSteward</h2>
      <!-- 登录表单 -->
      <el-form :model="userInfo" :rules="rules" ref="userInfo">
        <el-form-item prop="userName">
          <el-input
            :placeholder="$t('login.userNamePrompt')"
            prefix-icon="el-icon-user"
            v-model="userInfo.userName"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            :placeholder="$t('login.passwordPrompt')"
            prefix-icon="iconfont icon-mima"
            v-model="userInfo.password"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-row :gutter="20">
            <el-col :span="15">
              <el-form-item prop="verifycode">
                <el-input
                  :placeholder="$t('login.verifycodePrompt')"
                  prefix-icon="iconfont icon-yanzhengyanzhengma"
                  v-model="userInfo.verifycode"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <span @click="refreshCode()">
                <identify :identifyCode="identifyCode"></identify>
              </span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toLogin('userInfo')">{{
            $t("login.login")
          }}</el-button>
          <el-button @click="resetForm('userInfo')">{{
            $t("login.reset")
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import identify from "../components/identify.vue";
import I18n from "@/lang";
import { login } from "../api/login.js";
// import {roleQuery} from "api/user.js"
// import Icon from "../components/Icon.vue"
export default {
  components: { identify },
  data() {
    const validateVerifycode = (rule, value, callback) => {
      const newVal = value.toLowerCase();
      const identifyStr = this.identifyCode.toLowerCase();
      if (newVal === "") {
        callback(new Error(I18n.t("login.verifycodePrompt")));
      } else if (newVal !== identifyStr) {
        console.log("validateVerifycode:", value);
        callback(new Error(I18n.t("login.verifycodeError")));
      } else {
        callback();
      }
    };
    return {
      userInfo: {
        userName: "",
        password: "",
        verifycode: "",
        role: 0, //0管理员，1游客
      },
      lang: null,
      // 图片验证码
      identifyCode: "",
      // 验证码规则
      identifyCodes: "123456789ABCDEFGHGKMNPQRSTUVWXYabcdefghijkmnopqrstuvwxy",
      rules: {
        userName: [
          {
            required: true,
            message: I18n.t("login.userNamePrompt"),
            trigger: "blur",
          },
          // {
          //   message: I18n.t("prompt.checkUsername"),
          //   trigger: "blur",
          //   pattern: "/^[a-zA-Z][a-zA-Z0-9]{3,15}$/",
          // },
        ],
        password: [
          {
            required: true,
            message: I18n.t("login.passwordPrompt"),
            trigger: "blur",
          },
          // {
          //   message: I18n.t("prompt.checkPassword"),
          //   trigger: "blur",
          //   pattern: "/^[a-zA-Z0-9]+$/",
          // },
        ],
        verifycode: [
          { required: true, trigger: "blur", validator: validateVerifycode },
        ],
      },
    };
  },
  mounted() {
    this.initialize();
    this.refreshCode();
  },
  methods: {
    initialize() {
      this.$i18n.locale == "zh-CN" ? (this.lang = true) : (this.lang = false);
    },
    changeLanguage() {
      // console.log(this.$i18n.locale);
      this.$i18n.locale == "zh-CN"
        ? (this.$i18n.locale = "en-CN")
        : (this.$i18n.locale = "zh-CN"); //设置中英文模式
      this.$i18n.locale == "zh-CN" ? (this.lang = true) : (this.lang = false);
      localStorage.setItem("languageSet", this.$i18n.locale); //将用户设置存储到localStorage以便用户下次打开时使用此设置
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[
            Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
          ];
      }
    },
    toLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            name: this.userInfo.userName,
            password: this.userInfo.password,
          };
          localStorage.removeItem("stewardToken");
          login(obj).then((res) => {
            console.log(res.data);
            let code = res.data.code;
            console.log(code, "------------");
            if (code === 200) {
              let token = res.data.data;
              var strings = token.split("."); //通过split()方法将token转为字符串数组
              //取strings[1]数组中的第二个字符进行解析
              var userinfo = JSON.parse(
                decodeURIComponent(
                  escape(
                    window.atob(
                      strings[1].replace(/-/g, "+").replace(/_/g, "/")
                    )
                  )
                )
              );
              //然后可以拿到解析后的数据，可以console.log()打印下，
              console.log(userinfo.user_id, "userinfo");
              localStorage.setItem("stewardUserId", userinfo.user_id);
              localStorage.setItem("stewardToken", token);
              localStorage.setItem("stewardUser", this.userInfo.userName);
              //
              const time = new Date().getTime() + 60 * 60 * 1000 * 3;
              // const time = new Date().getTime() + 6 * 60 * 1000;
              // const time=new Date().getTime();
              localStorage.setItem("stewardExpireTime", time);
              this.$router.push({
                path: "/",
              });
            } else {
              // let data=res.data.data;
              this.$message({
                type: "warning",
                message: I18n.t("login.userError"),
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="stylus" scoped>
// *{
//   margin: 0 auto
//   padding: 0
// }
.login_box{
    width: 100%
    height: 100vh
    background: url("../assets/imgs/login.jpg") no-repeat
    background-size: 100% 100%
    display: flex;
    align-items: center;
    position relative
    .container {
        // width 20%
        min-width 380px
        background-color: rgba(7, 9, 58, 0.8);
        // -moz-opacity:0.8;
        // opacity: 0.8
        position: absolute
        left: 20%
        padding: 40px 40px;
        .avatar_box{
            display: flex;
            justify-content: center;
            align-content: center;
        }
        h2 {
            text-align: center;
            margin-bottom: 30px;
            margin-top: 75px;
        }
    }
}

@font-face {
  font-family: "iconfont"; /* Project id 3199544 */
  src: url('//at.alicdn.com/t/font_3199544_8qxcjjx8jmp.woff2?t=1648102448666') format('woff2'),
       url('//at.alicdn.com/t/font_3199544_8qxcjjx8jmp.woff?t=1648102448666') format('woff'),
       url('//at.alicdn.com/t/font_3199544_8qxcjjx8jmp.ttf?t=1648102448666') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-yanzhengyanzhengma:before {
  content: "\e624";
}

.icon-mima:before {
  content: "\e8b2";
}
.icon-cn-en:before {
  content: "\e605";
}
.icon-zhongyingwenqiehuan2:before {
  content: "\e604";
}
</style>
